<template>
  <div class="w80-login my-20">
    <div class="row text-center">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="contenedor-form-login">
          <!-- <img class="headregister d-sm-none" src="../../../assets/HeaderLogin.png" alt="Mobile Logo" /> -->
          <h1 class="font-white font-regular">
            Bienvenido de nuevo a Mobil Predictor.
          </h1>
          <hr class="separador-h-login" />
          <p class="font-white font-regular mt-3 subtitulo-login">
            Únete a la emoción e inicia sesión en tu cuenta para disfrutar de la
            experiencia de predicción más emocionante de la Gran Carrera.
          </p>
          <p class="font-white font-regular subtitulo-login">
            Por favor inicia sesión en tu cuenta
          </p>
          <form action="" class="formulario-login" @submit.prevent="submitFormLogin">
            <div class="input-group container-input-login-email d-flex mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-user"></i>
                </span>
              </div>
              <input type="text" class="form-control font-regular input-email-login" id="email" v-model="form.email"
                placeholder="Correo" />
            </div>
            <div class="input-group container-input-login-password d-flex">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-lock"></i>
                </span>
              </div>
              <input :type="showPassword ? 'text' : 'password'" class="form-control font-regular input-password-login"
                id="password" v-model="form.password" placeholder="Contraseña" />
              <i class="fa fa-eye password-icon-login" :class="{ 'fa-eye-slash': showPassword }"
                @click="showPassword = !showPassword"></i>
            </div>
            <button class="btn btn-facebook mt-3" @click="submitFormLogin">
              Entrar
            </button>
          </form>
          <div class="mt-3 container-recordar-pwd">
            <input type="checkbox" v-model="form.rememberMe" id="rememberMe" class="remember-me-checkbox" />
            <label for="rememberMe" class="font-white font-regular remember-me-label">Recordar contraseña</label>
            <div class="espacio-blanco"></div>
            <button class="font-white font-regular iniciarSesionLogin"
              @click="redirectWithTimeout('/olvide-contrasena')">
              ¿Olvidaste tu contraseña?
            </button>
          </div>
          <div class="row divisor-dos-partes">
            <div class="col-5">
              <hr class="divisor-parte1" />
            </div>
            <div class="col-1 divisor-o">O</div>
            <div class="col-5">
              <hr class="divisor-parte1" />
            </div>
          </div>
          <div class="mt-1 text-center container-button-google">
            <button class="btn btn-google" @click="triggerGoogleSignIn">
              <i><img class="icon_google" src="@/assets/2024/IconGoogle_login.png" alt="Google_icon" /></i>
              Inicia sesión con Google
            </button>
          </div>
          <!-- <div class="mt-1 text-center container-button-facebook">
            <button class="btn btn-facebook" @click="loginWithFacebook">
              <i
                ><img
                  class="icon_google"
                  src="@/assets/2024/IconFacebook_login.png"
                  alt="Facebook_icon"
              /></i>
              Inicia sesión con Facebook
            </button>
          </div> -->
        </div>
      </div>
    </div>
    <div class="row px-10 d-flex align-stretch justify-end">
      <p class="font-white text-home-brand italica">Powered by Mobil<sup>®</sup> Racing Club</p>
    </div>
  </div>
</template>

<script src="./iniciarSesion.js" />
<style src="./iniciarSesion.scss" lang="scss" />
<script src="./googleAuth.js" />
<style src="../../../App.scss" lang="scss" />
<script>
import axios from "axios";
import Swal from "sweetalert2";
// import { useGAuth } from 'vue3-google-oauth2';

export default {
  name: "iniciarSesion",
  metaInfo: {
    title: "Inicio de sesión - Mobil Predictor",
    meta: [
      { name: "title", content: "Inicio de sesión - Mobil Predictor" },
      {
        name: "description",
        content:
          "¡la Gran Carrera está apunto de comenzar! Entra a Mobil Predictor y predice los resultados de Checo, Max y de Oracle Red Bull Racing.",
      },
    ],
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      showPassword: false,
    };
  },
  mounted() {
    fbq('track', 'Traffic');
    if (localStorage.getItem("predictor") && document.cookie.includes("predictor")) {
      this.$router.push("/dashboard");
    }
  },
  methods: {
    async submitFormLogin() {
      if (document.cookie.includes("login")) {
        axios.defaults.headers.common["Content-Type"] = "application/json";
        const response = await axios.post(
          "https://www.mobilpredictor.com.mx/datos/api/loginReturn",
          // "http://127.0.0.1:8000/api/loginReturn",
          this.form
        );
        // console.log(response.data);
        if (response.status === 201) {
          if (this.$route.path !== '/dashboard') {
            this.$router.push('/dashboard');
          }
          localStorage.setItem("predictor", JSON.stringify(response.data));
          const expirationDate = new Date();
          expirationDate.setDate(expirationDate.getDate() + 30);
          document.cookie = `predictor=${JSON.stringify(
            response.data.email
          )}; expires=${expirationDate.toUTCString()}; path=/`;
        }
      } else {
        try {
          axios.defaults.headers.common["Content-Type"] = "application/json";
          const response = await axios.post(
            "https://www.mobilpredictor.com.mx/datos/api/login",
            // "http://127.0.0.1:8000/api/login",
            this.form
          );
          // console.log(response.data);
          if (response.status === 201) {
            if (this.$route.path !== '/doble-auth') {
              this.$router.push("/doble-auth");
        }

            localStorage.setItem("predictor", JSON.stringify(response.data));
            const expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + 30);
            document.cookie = `predictor=${JSON.stringify(
              response.data.email
            )}; expires=${expirationDate.toUTCString()}; path=/`;

            document.cookie = `login=${JSON.stringify(
              response.data.email
            )}; expires=${expirationDate.toUTCString()}; path=/`;
          }
        } catch (error) {
          // console.error(error);
          Swal.fire({
            title: "Datos inválidos",
            text:
              "El correo o la contraseña son incorrectos, por favor intenta de nuevo.",
            icon: "warning",
            background: "rgba(0, 20, 79, 0.8)",
            confirmButtonColor: "#ff0000",
            color: "#fff",
          });
        }
      }
    },
    redirectWithTimeout(address) {
      setTimeout(() => {
        window.location.href = address;
      }, 1500);
    },
    triggerGoogleSignIn() {
      const client = google.accounts.oauth2.initTokenClient({
        client_id: "791428404348-dmp7rrt789mpt1kkqdk5grdc85jo2ev5",
        scope:
          "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
        callback: this.handleCredentialResponse,
      });
      client.requestAccessToken();
    },
    handleCredentialResponse(response) {
      // console.log("Token de acceso: ", response.access_token);

      // Aquí hacemos la petición para obtener los datos del usuario
      fetch("https://www.googleapis.com/oauth2/v2/userinfo", {
        headers: {
          Authorization: `Bearer ${response.access_token}`,
        },
      })
        .then((res) => res.json())
        .then((userInfo) => {
          console.log("Información del usuario: ", userInfo);
          const userInfoArray = {
              email: userInfo.email,
              nombre: userInfo.given_name,
              apellidop: userInfo.family_name,
            };

            if (document.cookie.includes("login")) {
              axios.defaults.headers.common["Content-Type"] = "application/json";
              axios.post(
                "https://www.mobilpredictor.com.mx/datos/api/loginGoogleReturn",
                // "http://127.0.0.1:8000/api/loginGoogleReturn",
                userInfoArray
              )
              .then((response) => {
                if (response.status === 201) {
                  if (this.$route.path !== '/dashboard') {
            this.$router.push('/dashboard');
          }
                  localStorage.setItem("predictor", JSON.stringify(response.data));
                  const expirationDate = new Date();
                  expirationDate.setDate(expirationDate.getDate() + 30);
                  document.cookie = `predictor=${JSON.stringify(
                    response.data.email
                  )}; expires=${expirationDate.toUTCString()}; path=/`;
                }
              })
              .catch((error) => {
                console.error("Error al obtener la información del usuario: ", error);
              });
            }else{
              axios.defaults.headers.common["Content-Type"] = "application/json";
              axios.post(
                "https://www.mobilpredictor.com.mx/datos/api/loginGoogle",
                // "http://127.0.0.1:8000/api/loginGoogle",
                userInfoArray
              )
              .then((response) => {
                if (response.status === 201) {
                  if (this.$route.path !== '/doble-auth') {
                    this.$router.push("/doble-auth");
                  }
                  localStorage.setItem("predictor", JSON.stringify(response.data));
                  const expirationDate = new Date();
                  expirationDate.setDate(expirationDate.getDate() + 30);
                  document.cookie = `predictor=${JSON.stringify(
                    response.data.email
                  )}; expires=${expirationDate.toUTCString()}; path=/`;

                  document.cookie = `login=${JSON.stringify(
                    response.data.email
                  )}; expires=${expirationDate.toUTCString()}; path=/`;
                }
              })
              .catch((error) => {
                console.error("Error al obtener la información del usuario: ", error);
              });

            }
        })
        .catch((error) => {
          console.error("Error al obtener la información del usuario: ", error);
        });
    },
    // async loginWithGoogle() {
    //   const auth2 = gapi.auth2.getAuthInstance();
    //   try {
    //     const googleUser = await auth2.signIn();
    //     const profile = googleUser.getBasicProfile();
    //     const idToken = googleUser.getAuthResponse().id_token;

    //     console.log('ID Token:', idToken);
    //     console.log('Name:', profile.getName());
    //     console.log('Email:', profile.getEmail());

    //     // Aquí puedes enviar el token al backend para autenticar al usuario
    //   } catch (error) {
    //     console.error('Error al iniciar sesión con Google:', error);
    //   }
    // },
    loginWithFacebook() {
      console.log("Facebook");
    },
  },
};
</script>
