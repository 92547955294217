<template>
  <div class="w80-tyc px-10 my-20">
    <!-- <img class="rb20tyc text-center" src="../../../assets/RB20.png" alt=""> -->

    <h3 class="font-white font-regular font-regular mt-5">BASES LEGALES DE LA PROMOCIÓN</h3>
    <h3 class="font-white font-regular">MOBIL PREDICTOR 2024</h3>
    <p class="font-white font-regular">
      Toda persona que, conforme a las presentes Bases, desee participar en la promoción, “MOBIL PREDICTOR 2024” (en lo
      sucesivo, la “promoción”), acepta cumplir con la totalidad de éstas, y al participar se entenderá que ha leído,
      entendido y comprendido íntegramente estas Bases, por lo que se compromete a cumplir totalmente con los requisitos
      y condiciones aquí establecidos (en adelante, las “Bases”).
    </p>
    <p class="font-white font-regular">La participación en esta promoción, implica el conocimiento y la aceptación de
      las Bases y la mecánica aquí señalada por parte de los "Participantes (según dicho término se define más
      adelante), así como el Aviso de Privacidad disponible en <a
        href="https://corporate.exxonmobil.com/Global-legal-pages/privacy-policy_mexico-spanish_marketing">https://corporate.exxonmobil.com/Global-legal-pages/privacy-policy_mexico-spanish_marketing</a>.
      Es indispensable estar de acuerdo con los términos, requisitos y condiciones establecidas en estas Bases, pues su
      aceptación implica regirse por las mismas para el desarrollo de la presente promoción. Las políticas, mecánica y
      restricciones no son apelables, ni están sujetas a negociación o modificación de ninguna índole.
    </p>
    <p class="font-white font-regular">Organizador: EVERY DAY MARKETING, S.C. (En adelante, “El Organizador”). </p>
    <p class="font-white font-regular">En todo momento será facultad exclusiva e inapelable del Organizador, la
      eliminación de un Participante por la falta de cumplimiento de cualquiera de las causales establecidas en las
      presentes Bases, términos y condiciones. </p>
    <p class="font-white font-regular">Es derecho y obligación de los Participantes conocer y cumplir las presentes
      Bases, términos y condiciones. Cada Participante asumirá la responsabilidad de actualizarse e informarse
      regularmente acerca de dicha reglamentación, con el fin de conocer sus derechos y sus responsabilidades.</p>
    <h4 class="font-white font-regular">I. ARTÍCULOS O PRODUCTOS POR PROMOVER: </h4>
    <p class="font-white font-regular">Familia de lubricantes, anticongelantes y combustibles MOBIL. </p>
    <h4 class="font-white font-regular">II. PARTICIPANTES: </h4>
    <p class="font-white font-regular">El público consumidor en general, que reúna todos y cada uno de los siguientes
      requisitos, y que esté interesado en participar, podrá entonces ser considerado como participante (quien reúna
      dichos requisitos será denominado “Participante”): </p>
    <p class="font-white font-regular ps-5">1.- Personas físicas, mayores de edad, con residencia legal en la república
      mexicana.
    </p>
    <p class="font-white font-regular">Solo las personas que cumplan con la totalidad de los requisitos anteriormente
      descritos podrán ser consideradas como “Participantes” de la promoción. </p>
    <h4 class="font-white font-regular">III. VIGENCIA DE LA PROMOCIÓN: </h4>
    <p class="font-white font-regular">El presente periodo de participación inicia a las 00:00 horas del día 4 de Junio
      de 2024, y termina a las 23:59:00 horas del día 27 de Octubre de 2024. (En lo sucesivo la “Vigencia”). </p>
    <h3 class="font-white font-regular">Cobertura de la promoción: DIGITAL A NIVEL NACIONAL DENTRO DE LA REPÚBLICA
      MEXICANA. </h3>
    <p class="font-white font-regular">La promoción es exclusivamente para los residentes legales de la república
      mexicana. </p>
    <h3 class="font-white font-regular">IV. MECÁNICA DE LA PROMOCIÓN: </h3>
    <p class="font-white font-regular">El Interesado, tendrá la oportunidad de participar en la promoción “MOBIL
      PREDICTOR 2024”. El participante, tendrá la oportunidad de visitar el sitio <a
        href="www.mobilpredictor.com.mx">www.mobilpredictor.com.mx</a> y darse de alta como usuario y comenzar a
      participar haciendo las predicciones sobre los LAS CARRERAS DE LA ESCUDERÍA ORACLE RED BULL RACING de la temporada
      2024, cerrando con el GRAN PREMIO DE MEXICO, el 27 Octubre de 2024. </p>
    <p class="font-white font-regular">Cada Participante tendrá una cuenta registrada de usuario y podrá registrar todas
      las predicciones que desee, (NO HAY LIMITE DE PARTICIPACIONES), o que le sea posible registrar, durante el periodo
      de la promoción. </p>
    <p class="font-white font-regular">Toda la información que guiará al participante, aparece en el portal <a
        href="www.mobilpredictor.com.mx" target="_blank">www.mobilpredictor.com.mx</a>. </p>
    <p class="font-white font-regular">Las predicciones se abrirán los lunes (12:00 am) HORA CENTRAL DE LA REPUBLICA
      MEXICANA, de cada semana de GRAN CARRERA y se cerrará el sábado, antes de la clasificación (a excepción de los la
      gran carrera de Austria grandes premios de AUSTRIA y ESTADOS UNIDOS (Texas). Confirmar o cinco minutos antes de la
      clasificación. </p>
    <p class="font-white font-regular">Las predicciones de los las CARRERAS DE ORACLE RED BULL RACING, que cuenten con
      “SPRINT RACE”, TENDRÁN SUS PROPIOS HORARIOS, dependiendo del lugar, en donde se efectué el Gran Premio. </p>
    <p class="font-white font-regular ps-5">A) AUSTRIA: Las predicciones inician el lunes 12:00 am , (hora central de la
      república mexicana),y cerrarán el viernes a las 08:59am( la clasificación es el viernes a las 09:00 am). </p>
    <p class="font-white font-regular ps-5">B) ESTADOS UNIDOS (TEXAS), las predicciones inician el día lunes 12:00 am y
      cerrarán el viernes a las 02:59 pm ( la clasificación es el viernes a las 03:00 pm). </p>
    <p class="font-white font-regular">La participación deberá ser Única, si se detecta doble registro, esta
      participación, quedará anulada. [Se requiere añadir exclusión sobre participación fraudulenta: usuario con
      múltiples correos o mismo usuario con múltiples registros, familiares y amigos de ExxonMobil, comportamientos
      evidentemente fraudulentos, como el uso de automatizaciones o bots.]</p>
    <p class="font-white font-regular">Para que una participación sea válida, se tendrán que responder todas las
      preguntas, tanto de pilotos, así como de Mobil. </p>
    <p class="font-white font-regular">Las preguntas Mobil serán 2 y se cambiarán cada Gran Carrera. </p>
    <p class="font-white font-regular">Los puntos serán acumulados, en base a las repuestas correctas, con base en la
      siguiente clasificación: </p>
    <p class="font-white font-regular">PILOTOS: MAX VERSTAPPEN Y SERGIO PÉREZ </p>
    <p class="font-white font-regular">100 PUNTOS, POR ACERTAR LA POSICIÓN FINAL EN EL GRAN PREMIO PARTICIPANTE. </p>
    <p class="font-white font-regular">100 PUNTOS, POR ACERTAR LA POSICIÓN FINAL EN LA CALIFICACIÓN. </p>
    <p class="font-white font-regular">50 PUNTOS, POR ACERTAR EL NUMERO DE PARADAS EN LOS PITS. </p>
    <p class="font-white font-regular">50 PUNTOS, POR ACERTAR EL TIPO DE NEUMÁTICO, AL INICIAR EL GRAN PREMIO. </p>
    <p class="font-white font-regular">25 PUNTOS, POR ACERTAR SI EL PILOTO, ES DECLARADO EL PILOTO DEL DIA. </p>
    <p class="font-white font-regular">50 PUNTOS, AL CONTESTAR LA PRIMERA PREGUNTA MOBIL ASIGNADA A LA CARRERA. </p>
    <p class="font-white font-regular">50 PUNTOS, AL CONTESTAR LA SEGUNDA PREGUNTA MOBIL ASIGNADA A LA CARRERA. </p>
    <p class="font-white font-regular">LOS CÓDIGOS PARA REDIMIR PUNTOS, SERÁN DE UN SOLO USO. </p>
    <p class="font-white font-regular">En caso de ganar la asistencia a un evento, los gastos de transporte, viáticos o
      alimentos serán a cuenta del participante. En ningún caso, ExxonMobil o sus representantes cubrirán estos gastos.
    </p>
    <p class="font-white font-regular">En caso de empate por puntuación, el criterio de desempate será por fecha y hora
      del Registro, el ganador será el primer participante en haberse registrado. </p>
    <p class="font-white font-regular">PREMIO MAYOR: Se eligirán a 40 ganadores de Premio Mayor. Este premio consistirá
      en acceso a un evento de la marca Mobil el 11 de octubre de 2024 en Ciudad de México en donde estará presente el
      piloto de Oracle Red Bull Racing, Sergio Pérez. Mobil se deslinda de la responsabilidad de la asistencia del
      piloto en la eventualidad de que se presente una causa ajena a la marca.
    </p>
    <p class="font-white font-regular">Se eligirán a los 20 usuarios registrados en Mobil Predictor con mayor puntuación
      histórica previo al Gran Premio de Singapur que se llevará a cabo el 22 de septiembre.</p>
    <p class="font-white font-regular">Se eligirán otros 20 usuarios que se registren a partir del 17 de septiembre y
      que obtengan las mejores puntuaciones en la Carrera de Singapur que se llevará a cabo el 22 de septiembre. </p>
    <p class="font-white font-regular">PREMIO - GRAN PREMIO DE ABU DABI:</p>
    <p class="font-white font-regular">Se entregarán premios con base en las puntuaciones obtenidas en el Gran Premio de
      Fórmula 1 en Abu Dabi que se celebrará el 7 y 8 de diciembre de 2024. Se entregará una gorra autografiada por
      Checo Pérez a las 14 (catorce) mejores puntuaciones. </p>
    <p class="font-white font-regular">Se entregará un kit de gorra con pines no autografiada a las siguientes 190
      (ciento noventa) personas con mejor puntuación dentro de la tabla. </p>
    <p class="font-white font-regular">Los ganadores serán contactados vía correo electrónico o número telefónico
      registrado. Tendrán 5 días hábiles para contestar al correo/mensaje de lo contrario, no podrá reclamar el premio y
      será asignado a la siguiente persona con mejor puntuación.</p>
    <h4 class="font-white font-regular">RESTRICCIONES </h4>
    <p class="font-white font-regular">Participantes que hayan resultado ganadores en dinámicas previas de Mobil
      Predictor quedarán excluidos de la posibilidad de ganar en esta ocasión. </p>
    <p class="font-white font-regular">Esta exclusión aplica tanto para la categoría de las 14 mejores puntuaciones como
      para el resto de premios mencionados.</p>
    <h4 class="font-white font-regular">ELEGIBILIDAD</h4>
    <p class="font-white font-regular">Solo se considerarán válidas las participaciones realizadas dentro del periodo
      establecido para el Gran Premio de Abu Dhabi</p>
    <p class="font-white font-regular ps-5">PREMIOS MENORES: Se comunicarán de forma mensual vía los correos
      electrónicos registrados de los participantes.</p>
    <p class="font-white font-regular ps-5">El criterio de desempate será por fecha y hora del Registro, el
      ganador será el primer participante en haberse registrado.</p>
    <p class="font-white font-regular ps-5">PARA HACER ACREEDOR A LOS PREMIOS, LOS GANADORES
      DEBERAN SEGUIR LAS REDES SOCIALES DE MOBIL MEXICO EN FACEBOOK E INSTAGRAM, A SU DIRECCIÓN DE CORREO REGISTRADA,
      MOBILPREDICTOR.COM.MX Y ESTAR INSCRITOS EN MOBIL RACING CLUB.</p>
    <p class="font-white font-regular ps-5">Canales de comunicación de la promocion: </p>
    <p class="font-white font-regular ps-5">REDES SOCIALES: MOBIL MEXICO EN FACEBOOK E INSTAGRAM, EMAIL,
      MOBILPREDICTOR.COM.MX, MOBIL RACING CLUB </p>
    <p class="font-white font-regular ps-5">Por lo anterior, El Organizador se reserva el derecho de modificar,
      cambiar,
      cancelar, prolongar, disminuir, posponer o anular las vigencias, requisitos, términos y condiciones, así como
      los
      Incentivos a entregar con motivo de la promoción, si a su juicio existieran elementos que impidan su
      realización,
      obligándose a notificarlo a sus participantes y a las autoridades competentes en la materia.</p>
    <h4 class="font-white font-regular">V. RESPONSABLE DE LA PROMOCION:</h4>
    <p class="font-white font-regular ps-5">EVERY DAY MARKETING, S.C. (EL ORGANIZADOR).</p>
    <p class="font-white font-regular ps-5">Con domicilio en Libramiento norte poniente 2851, col. Miravalle. Tuxtla
      Gutierrez, Chiapas. 29039. </p>
    <p class="font-white font-regular ps-5">Contacto: <a
        href="https://www.facebook.com/MobilMexico">https://www.facebook.com/MobilMexico</a></p>
    <p class="font-white font-regular ps-5">a. EVERY DAY MARKETING se hará responsable de la activación sobre 2 ejes:
    </p>
    <p class="font-white font-regular ps-5">b. EVERY DAY MARKETING, funciona como el Organizador del Concurso y es la
      cara corporativa ante las siguientes autoridades: PROFECO y TESORERIA DE LA CIUDAD DE MEXICO.</p>
    <p class="font-white font-regular ps-5">c. En el caso de Profeco, atenderá cualquier queja, que algún consumidor,
      pudiera presentar ante esta autoridad y lo resolverá, de acuerdo a lo establecido, dentro de los términos
      legales
      del concurso.</p>
    <p class="font-white font-regular ps-5">d. En el caso de la Tesoreria de la Ciudad de Mexico, seremos los
      responsables de aclararles, que el Concurso, no esta registrado en la ciudad de Mexico , y que nuestra empresa
      esta constituida en la ciudad de Tuxtla Gutierrez, Chiapas, eximiendo de cualquier pago, por concepto de RIFAS,
      SORTEOS Y CONCURSOS. </p>
    <p class="font-white font-regular ps-5">e. Cualquier otro tema relacionado con el concurso, será visto en su
      momento. </p>

  </div>
  <!-- <h4 class="font-white font-regular">AVISO DE PRIVACIDAD </h4> -->
  <!-- <a href="https://corporate.exxonmobil.com/Global-legal-pages/privacy-policy_mexico-spanish_marketing" target="_blank">https://corporate.exxonmobil.com/Global-legal-pages/privacy-policy_mexico-spanish_marketing  </a> -->
</template>

<style src="./tyc.scss" lang="scss" />
<script src="../iniciarSesion/iniciarSesion.js" />
<style src="../iniciarSesion/iniciarSesion.scss" lang="scss" />
<script>
import axios from "axios";
import swal from "sweetalert2";

export default {
  name: 'password',
  metaInfo: {
    title: 'Mobil Predictor',
    meta: [
      { name: 'title', content: 'Mobil Predictor' },
      { name: 'description', content: '¿Eres fanático de Oracle Red Bull Racing, Sergio Pérez y Max verstappen? Únete a la comunidad Mobil Racing Club y predice sus resultados en Mobil Predictor' }
    ]
  }
}
</script>
