<template>
  <div class="w80 my-20">
    <div class="row text-center">
      <div class="col-lg-1 col-md-1 d-none d-md-block" v-if="$vuetify.breakpoint.mdAndUp"></div>
      <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12">
        <div class="contenedor-form-dobleauth">
          <!-- <img class="head2fa d-sm-none " src="../../../assets/registerHead.png" alt="Mobile Logo"> -->
          <h1 class="font-white font-bold text-center dobleauth-titulo">Verifica tu inicio de sesión</h1>
          <p class="font-white font-regular text-center dobleauth-texto mt-5 px-5">Hemos enviado un código de
            verificación
            a tu correo
            electrónico.</p>
          <p class="font-white font-regular text-center dobleauth-texto">
            Ingresa el código de 6 dígitos aquí.</p>
          <p class="font-white font-regular text-center dobleauth-texto mb-5">
            *Asegúrate de revisar tu bandeja de Spam o Correos no deseados y marcar nuestros mails como “No es spam”.
          </p>
          <div class="formAuth">
            <h2 class="font-white font-bold text-center mb-5">Código</h2>
            <form action="" class="mt-5" @submit.prevent="submitForm2FA">
              <div class="form-group mb-5">
                <div class="d-flex d-flex justify-center">
                  <input type="text" class="form-control input-f2a mx-2" v-model="form.dfa1" id="cd1"
                    placeholder="000000" maxlength="6">
                  <!-- <input type="text" class="form-control input-f2a mx-2" v-model="form.dfa2" id="cd2" placeholder="00"
                    max="99" maxlength="2" @input="focusNextInput('cd3', $event)">
                  <input type="text" class="form-control input-f2a mx-2" v-model="form.dfa3" id="cd3" placeholder="00"
                    max="99" maxlength="2" ref="dfa3"> -->
                </div>
                <div class="d-flex justify-center mt-10">
                  <button class="btn btn-primary btn-lg btn-launch-dfa">Verificar</button>
                </div>
              </div>
            </form>
            <h2 class="font-white font-bold text-center mt-5 mb-5">¿No recibiste el correo?</h2>
            <div class="d-flex justify-center mt-5">
              <button id="reenviar2FA" class="btn btn-primary btn-lg btn-resend-dfa"
                @click="resendEmail()">Reintentar</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-1 col-md-1 d-none d-md-block" v-if="$vuetify.breakpoint.mdAndUp"></div>
    </div>
  </div>
</template>

<script src="./dobleAuth.js" />
<style src="./dobleAuth.scss" lang="scss" />
<style src="../../../App.scss" lang="scss" />
<script>
import axios from 'axios';
import swal from 'sweetalert2';

export default {
  data() {
    return {
      form: {
        dfa1: '',
        dfa2: '',
        dfa3: '',
        email: localStorage.getItem('predictor') ? JSON.parse(localStorage.getItem('predictor')).email : '',
      },
    };
  },
  mounted() {
    if (!localStorage.getItem('predictor')) {
      this.$router.push('/iniciar-sesion');
    }
  },
  methods: {
    async submitForm2FA() {
      try {
        const verificationCode = this.form.dfa1.toString();
        const response = await axios.post('https://www.mobilpredictor.com.mx/datos/api/code2FA', { verification_code: verificationCode, email: this.form.email});
        // const response = await axios.post('http://127.0.0.1:8000/api/code2FA', { verification_code: verificationCode, email: this.form.email});
        // console.log(response.data);
        // console.log(response.status);
        if (response.data.message == 'CodigoCorrecto') {
          if (this.$route.path !== '/dashboard') {
          this.$router.push('/dashboard');
        }
        }
      } catch (error) {
        // console.error(error);
        swal.fire({
          icon: 'error',
          title: '¡Ups!',
          text: 'El código de verificación es incorrecto. Por favor, intenta de nuevo.',
          background: "rgba(0, 20, 79, 0.8)",
          confirmButtonColor: "#ff0000",
          color: "#fff",
        });
      }
    },
    async resendEmail() {
      try {
        const email = localStorage.getItem('predictor') ? JSON.parse(localStorage.getItem('predictor')).email : '';
        if (!email) {
          swal.fire({
            icon: "info",
            title: "¡Lo sentimos!",
            text: "No se encontró el correo electrónico",
            background: "rgba(0, 20, 79, 0.8)",
            confirmButtonColor: "#ff0000",
              color: "#fff",
          });
          setTimeout(() => {
            this.$router.push('/iniciar-sesion');
          }, 3000);
        }

        const response = await axios.post('https://www.mobilpredictor.com.mx/datos/api/resendCode2FA', { email });
        // const response = await axios.post('http://127.0.0.1:8000/api/resendCode2FA', { email });
        if (response.data.message == 'SendEmail') {
          swal.fire({
            icon: "success",
            title: "Success",
            text: "Correo reenviado exitosamente",
            background: "rgba(0, 20, 79, 0.8)",
            confirmButtonColor: "#ff0000",
              color: "#fff",
          });
        }
      } catch (error) {
        // console.error(error);
        if (error.response.data.message == 'UserNotFound') {
          swal.fire({
            icon: "warning",
            title: "Ocurrió un error inesperado",
            text: "Por favor revise la cuenta de correo e intenta de nuevo.",
            background: "rgba(0, 20, 79, 0.8)",
            confirmButtonColor: "#ff0000",
              color: "#fff",
          });
        }
      }
    },
    focusNextInput(nextInput, event) {
      // Obtener el input actual y su valor
      const currentInput = event.target;
      const currentValue = currentInput.value;

      // Si el valor actual tiene la longitud máxima
      if (currentValue.length === currentInput.maxLength) {
        // Encontrar el siguiente input
        const nextInputEl = document.getElementById(nextInput);
        if (nextInputEl) {
          // Enfocar el siguiente input
          nextInputEl.focus();
        }
      }
    }
  },
};
</script>
