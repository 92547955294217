<template>
  <div class="w80-crpwd my-20">
    <div class="row text-center">
      <div class="col-lg-6 col-md-7 col-sm-12 col-xs-12">
        <div class="contenedor-form-crearPassword">
          <!-- <img
            class="headregisterCP d-sm-none"
            src="../../../assets/registerHead.png"
            alt="Mobile Logo"
          /> -->
          <h1 class="font-white font-bold crearPassword-titulo">
            Bienvenido de nuevo a Mobil Predictor
          </h1>
          <p class="font-white font-regular crearPassword-texto mt-5 mb-5">
            Acelera hacia la emoción e inicia sesión en tu cuenta para disfrutar
            de la experiencia de predicción más emocionante de la Gran Carrera.
          </p>

          <p class="font-white font-bold crearPassword-segundo-titulo mb-5">
            Crear contraseña
          </p>
          <form action="" class="mt-5 w100" @submit.prevent="submitFormCrearContrasena">
            <div class="form-group mb-5 BC">
              <input :type="showPassword3 ? 'text' : 'password'" class="form-control input-crear-password" id="password"
                v-model="form.password" placeholder="Contraseña*" />
              <i class="fa fa-eye password-icon-cc" @click="showPassword3 = !showPassword3"></i>
            </div>
            <div class="form-group mb-5 BC">
              <input :type="showPassword4 ? 'text' : 'password'" class="form-control input-crear-password"
                id="confirm_password" v-model="form.confirm_password" placeholder="Confirmar contraseña*" />
              <i class="fa fa-eye password-icon-cc" @click="showPassword4 = !showPassword4"></i>
            </div>
            <div class="text-center mt-10 mb-5">
              <button class="btn btn-primary btn-lg btn-launch-crpwd registrarse">
                Confirmar contraseña
              </button>
            </div>
          </form>
          <!-- <a class="font-white mt-5" href="">Olvidaste tu contraseña</a> -->
        </div>
      </div>
      <div class="col-lg-6 col-md-5 col-xs-12 d-none d-md-block">
        <img class="CrearPasswordImage" src="../../../../src/assets/2024/MaxCheco_registro.png" alt="ChecoMax">
      </div>
    </div>
  </div>
</template>

<script src="./crearPassword.js" />
<style src="./crearPassword.scss" lang="scss" />
<style src="../../../App.scss" lang="scss" />
<script>
import axios from "axios";
import swal from "sweetalert2";

export default {
  name: 'password',
  metaInfo: {
    title: 'Crea tu contraseña - Mobil Predictor',
    meta: [
      { name: 'title', content: 'Crea tu contraseña - Mobil Predictor' },
      { name: 'description', content: 'Estás a punto de ser parte de Mobil Predictor. Crea tu contraseña y empieza a participar' }
    ]
  },
  data() {
    return {
      form: {
        password: "",
        confirm_password: "",
        email: this.$route.params.correo,
      },
      showPassword3: false,
      showPassword4: false
    };
  },
  methods: {
    async submitFormCrearContrasena() {
      try {
        axios.defaults.headers.common["Content-Type"] = "application/json";
        const response = await axios.post(
          "https://www.mobilpredictor.com.mx/datos/api/crearContrasenaSF",
          // "http://127.0.0.1:8000/api/crearContrasenaSF",
          this.form
        );

        if (response.data.message == "ErrorPasswords") {
          swal.fire({
            icon: "error",
            title: "Error",
            text: "Las contraseñas no coinciden",
            background: "rgba(0, 20, 79, 0.8)",
            confirmButtonColor: "#ff0000",
              color: "#fff",
          });
        }

        if (response.data.message == "ErrorUserNotFound") {
          swal.fire({
            icon: "error",
            title: "Error",
            text: "Hubo un error con tu cuenta, volvamos a buscarla en Mobil ® Racing Club",
            background: "rgba(0, 20, 79, 0.8)",
            confirmButtonColor: "#ff0000",
              color: "#fff",
          });
          setTimeout(() => {
            this.$router.push("/comprueba-cuenta");
          }, 3000);
        }

        if (response.data.message == "UserPasswordUpdated") {
          swal.fire({
            icon: "success",
            title: "Contraseña actualizada",
            text: "Tu contraseña ha sido actualizada con éxito, te redireccionaremos al último paso de tu registro.",
            background: "rgba(0, 20, 79, 0.8)",
            confirmButtonColor: "#ff0000",
              color: "#fff",
          });

          const userData = {
            message: "Usuario registrado correctamente",
            email: this.$route.params.correo,
            code: 201,
          };

          localStorage.setItem("predictor", JSON.stringify(userData));

          const expirationDate = new Date();
          expirationDate.setDate(expirationDate.getDate() + 40);

          document.cookie = `predictor=${this.$route.params.correo}; expires=${expirationDate.toUTCString()}; path=/`;

          setTimeout(() => {
            this.$router.push("/doble-auth");
          }, 3000);
        }
      } catch (error) {
        // console.error(error);
      }
    },
  },
};
</script>
